<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="DataTable.data"
      :max-items="DataTable.length"
      :total="DataTable.total_record"
      @search="handleSearch"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.DataTable.start }} - {{ this.DataTable.end }} of
              {{ this.DataTable.total_record }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in DataTable.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th>Action</vs-th>
        <vs-th>CN Supplier ID</vs-th>
        <vs-th>Supplier Data</vs-th>
        <vs-th>Reference</vs-th>
        <vs-th>COA/Value</vs-th>
        <vs-th>Date</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="flex gap-2">
              <vx-tooltip
                text="view"
                style="margin-left: auto; margin-right: auto"
                v-if="
                  tr.status == STS_WAITING_APPROVAL ||
                  tr.status == STS_APPROVED ||
                  tr.status == STS_CLOSED ||
                  tr.status == STS_REJECTED ||
                  tr.status == STS_CENCELED
                "
              >
                <vs-button
                  color="primary"
                  type="line"
                  icon-pack="feather"
                  v-on:click="handleView(tr)"
                  icon="icon-eye"
                />
              </vx-tooltip>
              <vx-tooltip
                :text="tr.status == STS_APPROVED ? 'inquiry' : 'edit'"
                style="margin-left: auto; margin-right: auto"
                v-if="
                  (tr.status == STS_OPEN || tr.status == STS_INQUIRY) &&
                  view != 'approval'
                "
              >
                <vs-button
                  color="primary"
                  type="line"
                  icon-pack="feather"
                  v-on:click="handleEdit(tr)"
                  icon="icon-edit"
                />
              </vx-tooltip>
              <vx-tooltip
                text="cancel"
                style="margin-left: auto; margin-right: auto"
                v-if="
                  (tr.status == STS_OPEN || tr.status == STS_INQUIRY) &&
                  view != 'approval'
                "
              >
                <vs-button
                  color="primary"
                  type="line"
                  icon-pack="feather"
                  v-on:click="changeStatus(STS_CENCELED, tr)"
                  icon="icon-x"
                />
              </vx-tooltip>
              <vx-tooltip
                text="reject"
                style="margin-left: auto; margin-right: auto"
                v-if="tr.status == STS_WAITING_APPROVAL && view == 'approval'"
              >
                <vs-button
                  color="primary"
                  type="line"
                  icon-pack="feather"
                  v-on:click="changeStatus(STS_REJECTED, tr)"
                  icon="icon-x"
                />
              </vx-tooltip>
              <vx-tooltip
                text="send to approval"
                style="margin-left: auto; margin-right: auto"
                v-if="
                  (tr.status == STS_OPEN || tr.status == STS_INQUIRY) &&
                  view != 'approval'
                "
              >
                <vs-button
                  color="primary"
                  type="line"
                  icon-pack="feather"
                  v-on:click="changeStatus(STS_WAITING_APPROVAL, tr)"
                  icon="icon-check"
                />
              </vx-tooltip>
              <vx-tooltip
                text="approve"
                style="margin-left: auto; margin-right: auto"
                v-if="tr.status == STS_WAITING_APPROVAL && view == 'approval'"
              >
                <vs-button
                  color="primary"
                  type="line"
                  icon-pack="feather"
                  v-on:click="changeStatus(STS_APPROVED, tr)"
                  icon="icon-check"
                />
              </vx-tooltip>
              <vx-tooltip
                text="print"
                v-if="tr.status == STS_APPROVED"
                style="margin-left: auto; margin-right: auto"
              >
                <vs-button
                  color="primary"
                  type="line"
                  v-on:click="print(tr)"
                  icon-pack="feather"
                  icon="icon-printer"
                />
              </vx-tooltip>
            </div>
          </vs-td>
          <vs-td>{{ tr.code }}</vs-td>
          <vs-td>
            <p>ID : {{ tr.supplier_code }}</p>
            <p>Name : {{ tr.supplier_name }}</p>
          </vs-td>
          <vs-td>{{ tr.reference }}</vs-td>
          <vs-td>
            <template v-for="(tr_l, i_l) in tr.credit_note_supplier_line">
              <!-- <vs-divider
                :key="'devider-line-' + i_l"
                v-if="tr_l.value_credit > 0 && i_l > 0"
              /> -->
              <p
                :class="i_l > 0 ? 'bt pt-1' : ''"
                v-if="tr_l.value_credit > 0"
                :key="'coa-line-' + i_l"
              >
                COA: {{ tr_l.chart_of_account_name }}
              </p>
              <p class="mb-1" v-if="tr_l.value_credit > 0" :key="'credit-line-' + i_l">
                Value Credit: {{ tr_l.value_credit }}
              </p>
            </template>
          </vs-td>
          <vs-td>
            <p>Document Date : {{ dateFormat(tr.date) }}</p>
            <p>Posting Date : {{ dateFormat(tr.posting_date) }}</p>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="
        DataTable.data.length > 0
          ? parseInt(DataTable.data.length / DataTable.length) == 0
            ? 1
            : parseInt(DataTable.data.length / DataTable.length)
          : 0
      "
      v-model="setPage"
    />
  </div>
</template>
<script>
import Template from "../../promotion_v2/discount/component/template";
import moment from "moment";
export default {
  props: ["DataTable", "view"],
  components: {
    Template,
  },
  data: () => ({
    STS_OPEN: 0,
    STS_INQUIRY: 1,
    STS_WAITING_APPROVAL: 2,
    STS_APPROVED: 3,
    STS_CLOSED: 4,
    STS_REJECTED: 5,
    STS_CENCELED: 6,
  }),
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
  mounted() {},
  methods: {
    dateFormat(date) {
      if (date) {
        return moment.utc(date).format("YYYY-MM-DD");
      }
    },
    handleChangePage(page) {
      this.$emit("ChangePage", page);
    },
    handleSearch(s) {
      this.$emit("Search", s);
    },
    handleChangelength(l) {
      this.$emit("ChangeLength", l);
    },
    handleView(dt) {
      this.$emit("ViewDetail", "view", dt);
    },
    changeStatus(sts, dt) {
      switch (sts) {
        case this.STS_WAITING_APPROVAL:
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Please confirm to send approval, code '" + dt.code + "'",
            accept: () => {
              this.$emit("ChangeStatus", sts, dt);
            },
          });
          break;
        case this.STS_APPROVED:
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Please confirm to approve, code '" + dt.code + "'",
            accept: () => {
              this.$emit("ChangeStatus", sts, dt);
            },
          });
          break;
        case this.STS_CENCELED:
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Please confirm to cancel, code '" + dt.code + "'",
            accept: () => {
              this.$emit("ChangeStatus", sts, dt);
            },
          });
          break;
        case this.STS_REJECTED:
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Please confirm to reject, code '" + dt.code + "'",
            accept: () => {
              this.$emit("ChangeStatus", sts, dt);
            },
          });
          break;
        default:
          this.$emit("ChangeStatus", sts, dt);
          break;
      }
    },
    handleEdit(dt) {
      this.$emit("ViewDetail", "edit", dt);
    },

    async copyToClipboard(toCopy) {
      try {
        await navigator.clipboard.writeText(toCopy);
        this.$vs.notify({
          title: "Success",
          text: "Copied to clipboard",
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check-circle",
        });
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: "Failed to copy to clipboard",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },

    closePopup() {
      this.isCloseButton = true;
      this.invoicePopup = false;
      this.reloadData(this.params);
    },

    isShowPopup(codes = []) {
      return codes.some((code) => code !== null);
    },
    print(tr) {
      let name = "cn-supplier-print";

      let routeData = this.$router.resolve({
        name: name,
        params: {
          id: tr.ID,
        },
      });
      console.log(routeData.href);
      console.log(routeData);
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
