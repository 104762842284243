<template>
  <div>
    <vx-card title="Credit Note Supplier">
      <vs-button
        v-on:click="handleCreate"
        icon="add"
        color="success"
        v-if="!isViewDetail && view != 'approval'"
      >
        Create
      </vs-button>
      <vs-button
        v-on:click="handleClose"
        icon="arrow_back"
        color="danger"
        v-if="isViewDetail"
      >
        Close
      </vs-button>
      <vs-row v-if="!isViewDetail" class="mt-3">
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="12"
        >
          Supplier ID
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-lg="3"
          vs-sm="4"
          vs-xs="12"
        >
          <multiselect
            class="selectExample w-full"
            v-model="selectSupplier.value"
            :options="selectSupplier.option"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            select-label=""
            deselect-label=""
            :searchable="selectSupplier.searchabel"
            :internal-search="selectSupplier.internal_search"
            :loading="selectSupplier.loading"
            :max-height="selectSupplier.max_height"
            :limit="selectSupplier.limit"
            :disabled="selectSupplier.disabled"
            v-validate="selectSupplier.validate"
            :name="selectSupplier.name"
            @input="onSelectedSupplier"
            placeholder="Type to search"
            @search-change="handlerSearchSupplier"
            :custom-label="selectSupplier.label"
          />
        </vs-col>
      </vs-row>
      <vs-row class="my-3" v-if="!isViewDetail">
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="12"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-lg="3"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-button v-on:click="handleFilter" color="primary"> Filter </vs-button>
        </vs-col>
      </vs-row>
      <vs-tabs :color="colorx" ref="tabs" v-show="!isViewDetail">
        <template v-for="(label, i) in tabs">
          <vs-tab
            @click="changeTab(i)"
            :label="label"
            :key="i"
            v-if="
              (view == 'approval' &&
                (i == STS_WAITING_APPROVAL || i == STS_APPROVED || i == STS_REJECTED)) ||
              view != 'approval'
            "
          >
            <div class="con-tab-ejemplo">
              <tab-view
                :DataTable="table"
                :view="view"
                @ChangeStatus="handleChangeStatus"
                @ChangePage="handleChangePage"
                @Search="handleSearch"
                @ChangeLength="handleChangelength"
                @ViewDetail="handleViewDetail"
              />
            </div>
          </vs-tab>
        </template>
      </vs-tabs>
      <div v-if="isViewDetail">
        <form-view
          @close="handleClose"
          :ReadOnly="readonlyView"
          :SelectDT="table.select"
          @ChangeTab="changeTab"
        />
      </div>
    </vx-card>
  </div>
</template>
<script>
import Template from "../../promotion_v2/discount/component/template";
import TabView from "./tab_view";
import FormView from "./form_view";
import { watch } from "vue";

export default {
  components: {
    Template,
    TabView,
    FormView,
  },

  data() {
    let view = this.$route.params.view;
    return {
      url_supplier: "/api/v1/master/suppliers",
      view: view,
      colorx: "danger",
      isReload: false,
      isViewDetail: false,
      cns_id: 0,
      readonlyView: false,
      allDataSuppliers: [],
      selectSupplier: {
        name: "supplier",
        loading: false,
        searchabel: true,
        internal_search: false,
        validate: "required",
        max_height: 200,
        disabled: false,
        limit: 5,
        option: [
          {
            id: 0,
            code: "All",
            name: "-",
          },
        ],
        value: {
          id: 0,
          code: "All",
          name: "All",
        },
        label: ({ code, name }) => {
          return `${code} - ${name}`;
        },
      },
      table: {
        search: "",
        length: 10,
        page: 1,
        total_record: 0,
        total_search: 0,
        select: {},
        data: [],
        order: "id",
        sort: "desc",
        status: 0,
        limits: [10, 25, 50, "All"],
      },
      tempData: [],
      url_cn_supplier: "/api/v2/finance/credit-note-supplier",
      url_update_sts: "/api/v2/finance/credit-note-supplier/change-status",
      tabs: ["New", "Inquiry", "Waiting", "Approved", "Closed", "Rejected", "Cenceled"],
      STS_OPEN: 0,
      STS_INQUIRY: 1,
      STS_WAITING_APPROVAL: 2,
      STS_APPROVED: 3,
      STS_CLOSED: 4,
      STS_REJECTED: 5,
      STS_CENCELED: 6,
    };
  },
  mounted() {
    if (this.view == "approval") {
      this.table.status = this.STS_WAITING_APPROVAL;
    }
    let supplier = this.getOptionSupplier();
    supplier.then((r) => {
      if (r.code != 200) {
        this.$vs.notify({
          color: "danger",
          title: r.code,
          text: r.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.selectSupplier.option = this.dataSuppliers;
        this.selectSupplier.option.unshift({
          id: 0,
          code: "All",
          name: "All",
        });
      }
    });
    this.getDataCnSupplier();
  },
  watch: {
    watchStatus: function () {
      this.getDataCnSupplier();
    },
  },
  computed: {
    watchStatus() {
      return this.table.status;
    },
  },
  methods: {
    handleFilter() {
      this.getDataCnSupplier();
    },
    onSelectedSupplier(d) {},
    handlerSearchSupplier(s) {
      if (s != "") {
        this.selectSupplier.loading = true;
        this.selectSupplier.search = s;
        let supplier = this.getOptionSupplier(s);
        supplier.then((r) => {
          if (this.selectSupplier.search == s) {
            this.selectSupplier.option = [];
            for (var supp = 0; supp < r.data.records.length; supp++) {
              this.selectSupplier.option.push({
                id: r.data.records[supp].ID,
                code: r.data.records[supp].Code,
                name: r.data.records[supp].Name,
              });
            }
            this.selectSupplier.loading = false;
          }
        });
      } else {
        this.selectSupplier.option = this.allDataSuppliers;
      }
    },

    getOptionSupplier(s = "") {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.url_supplier, {
            params: {
              search: s,
              length: 10,
              page: 1,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (s == "") {
                this.dataSuppliers = [];
                for (var supp = 0; supp < resp.data.records.length; supp++) {
                  this.dataSuppliers.push({
                    id: resp.data.records[supp].ID,
                    code: resp.data.records[supp].Code,
                    name: resp.data.records[supp].Name,
                  });
                }
                this.alldataSuppliers = this.dataSuppliers;
              }
              resolve(resp);
            } else {
              this.$vs.loading.close();
              reject(resp);
              // this.$router.push("/");
            }
          });
      });
    },
    onShow() {
      this.isReload = true;
      this.$nextTick(() => {
        this.isReload = false;
      });
    },
    changeTab(sts) {
      this.isReload = true;
      this.table.status = sts;
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getDataCnSupplier();
    },
    handleChangeStatus(sts, dt) {
      // this.table.page = page;
      // this.getDataCnSupplier();
      this.$vs.loading();
      this.$http
        .post(this.url_update_sts, {
          id: dt.ID,
          status: sts,
        })
        .then((r) => {
          this.$vs.loading.close();
          if (r.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: r.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            this.isReload = true;
            this.getDataCnSupplier();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: r.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: e.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    handleSearch(s) {
      this.table.search = s;
      this.getDataCnSupplier();
    },
    getDataCnSupplier() {
      console.log("this.getKey, ", this.getKey());
      console.log("this.table.status>>>", this.table.status);
      this.table.status = this.table.status ? this.table.status : 0;
      let supplierID = this.selectSupplier.value ? this.selectSupplier.value.id : 0;
      let filter = {
        supplier_id: supplierID,
        search: this.table.search,
        length: this.table.length,
        page: this.table.page,
        order: this.table.order,
        sort: this.table.sort,
        status: this.table.status,
      };
      //check expired temporary data
      if (!this.checkDataTemp() && !this.isReload) {
        console.log("ambil data dari temporary");
        this.placeDataTemp();
        return;
      }
      this.$vs.loading();
      const httpDataCns = this.getApi(this.url_cn_supplier, filter);
      httpDataCns
        .then((r) => {
          let now = new Date();
          this.$vs.loading.close();
          if (r.code == 200) {
            //hapus temporary data expired
            this.tempData = this.tempData.filter((el) => {
              return el.key != this.getKey();
            });
            r.data.records.forEach((el) => {
              el.key = this.getKey();
              now.setMinutes(now.getMinutes() + 1); //set expired temporary data
              // el.status = this.STS_WAITING_APPROVAL; //sementara buat keperluan dev
              el.timeMax = now;
              el.length = r.data.total_record_per_page;
              el.total_record = r.data.total_record;
              el.total_search = r.data.total_record_search;
              this.tempData.push(el);
            });
            this.placeDataTemp();
            this.isReload = false;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: r.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          console.log(e);
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: e.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    getKey() {
      return (
        this.table.search +
        "-" +
        this.table.status +
        "-" +
        this.table.page +
        "-" +
        this.table.length
      );
    },
    //untuk pengecekan expired temporary data
    checkDataTemp() {
      let reload = true;
      let now = new Date();
      this.tempData.every((el) => {
        if (el.key == this.getKey()) {
          if (new Date(el.timeMax) > now) {
            reload = false;
          }
          return false;
        }
        return true;
      });
      return reload;
    },
    placeDataTemp() {
      const data = this.tempData.filter((el) => {
        return el.key == this.getKey();
      });
      this.table.data = data;
      console.log("this.table.data", this.table.data);
      this.table.data.forEach((el) => {
        this.table.length = el.length;
        this.table.total_record = el.total_record;
        this.table.total_search = el.total_search;
      });
      if (this.table.data.length == 0) {
        this.table.total_record = 0;
        this.table.total_search = 0;
      }
      this.setStartEnd();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total_record : val;
      this.table.page = 1;
      this.getDataCnSupplier();
    },
    handleViewDetail(v, dt) {
      if (v == "view") {
        this.readonlyView = true;
      } else {
        this.readonlyView = false;
      }
      this.isViewDetail = true;
      this.table.select = dt;
    },
    setStartEnd() {
      let valStart = this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total_record) {
        valStart = 1;
      }
      if (this.table.total_record == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total_record) {
        valEnd = this.table.total_record;
      }
      if (this.table.total_search < this.table.total_record) {
        valEnd = this.table.total_search;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.isViewDetail = true;
      this.readonlyView = false;
    },
    handleClose(tab = 0, reload = false) {
      this.cns_id = 0;
      this.isViewDetail = false;
      this.table.select = {};
      if (reload) {
        this.isReload = reload;
        this.getDataCnSupplier();
      }
      // this.$refs.tabs.activeChild(tab);
    },
    getApi(url, params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(url, {
            params: {
              supplier_id: params.supplier_id,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              status: params.status,
              // type: params.type ? params.type : 0,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
</script>
